import React, { useState } from 'react';
import projects from './projects.json'
import './Projects.scss'
import Iframe from 'react-iframe'
import { Button, Stack } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

const Projects = () => {
  const [openProject, setOpenProject] = useState();

  const { ref, inView } = useInView({
    threshold: 0.4, // Triggers when 10% of the element is in view
    triggerOnce: false, // Animation only happens once
  });

  return (
    <section id="projects">
      <div className='info-panel d-none d-lg-block text-light pt-3 pb-1 px-3 mx-4'>
        <h2>Projects</h2>
        <ul>
          {projects.map((project, index) => (
            <li key={index} className='info-subpanel ps-4 pe-3 pt-3 pb-1 my-3' onClick={()=>setOpenProject(project)}>
              <h3>{project.title}</h3>
              <p className='fs-5 text-light text-opacity-75'>{project.summary}</p>
            </li>
          ))}
        </ul>
      </div>
      <div ref={ref} className={`info-panel ${inView ? 'in-view' : 'out-view'} d-lg-none d-block text-light pt-3 pb-1 px-3 mx-4`}>
      <h2>Projects</h2>
        <ul>
          {projects.map((project, index) => (
            <li key={index} ref={ref} className={`info-subpanel ${inView ? 'in-view' : 'out-view'} ps-4 pe-3 pt-3 pb-1 my-3`} onClick={()=>setOpenProject(project)}>
              <h3>{project.title}</h3>
              <p className='fs-5 text-light text-opacity-75'>{project.summary}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* {projects.map((project, index) => (
        <div key={index} style={{position:"fixed", width:"70%",height:"50%"}} className={`overflow-auto border top-25 start-15 border-3 ps-4 pe-3 pt-3 border-primary bg-secondary rounded ${(project == openProject) ? "d-block" : "d-none"}`}>
          <Stack direction='horizontal' className='justify-content-between'>
            <h2 className=''>{project.title}</h2>
            <Button className='btn-close border border-3' onClick={()=>setOpenProject(null)}></Button>
          </Stack>
          <p className='fs-6 text-light text-opacity-75 mb-1'>{project.date}</p>
          <h5>{project.summary}</h5>
          <p>{project.description}</p>
          <div className='row justify-content-center'>
          <h3 className='col-auto'>Skills</h3>
          <div className='w-100'></div>
          {project.skills.map((skill, index) => (
            <div key={index} className="col-auto text-light text-opacity-75 fs-5 mx-2 my-2 border border-2 border-primary bg-black bg-opacity-10 py-2 px-3 rounded text-center">
              {skill}
            </div>
          ))}
          </div>
        </div>
      ))} */}
    </section>
  );
};

export default Projects;
