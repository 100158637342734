import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className='info-panel text-light bg-blue bg-opacity-10 border-opacity-25 border border-3 border-blue pt-2 pb-0 px-3 m-4'>
      <h2>Contact</h2>
      <p><a href="mailto:george@goodey.co.uk" className='link link-blueAccent link-underline-opacity-0'>george@goodey.co.uk</a></p>
    </section>
  );
};

export default Contact;
